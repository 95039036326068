import { Navigate, useNavigate } from 'react-router-dom'
import useAuthentication from './UseAuthentication'
import { isTokenExpired, loadLocalUser } from '../utils/authenticationUtils'
import { useEffect } from 'react'
import { me } from '../modules/account/service/AccountService'
import { getMyHistory } from '../modules/exchange/service/ExchangeService'
import useExchange from './UseExchange'

interface PrivateRouteProps {
    Component: any
}

const PrivateRoute = ({ Component }: PrivateRouteProps) => {

    const navigate = useNavigate()
    const { user, setUser, logout } = useAuthentication()

    const { dispatch } = useExchange()
    useEffect(() => {
        if (isTokenExpired()) {
            setUser(null)
        } else {
            const localUser = loadLocalUser()
            if (localUser) {
                me({accessToken: localUser.accessToken})
                    .then(response => {
                        getMyHistory()
                            .then((chatHistory: any) => {
                                dispatch({ type: 'LOAD_HISTORY', payload: chatHistory })
                                setUser({
                                    accessToken: localUser.accessToken,
                                    email: response.email,
                                    name: response.name,
                                    photo: response.picture
                                })
                                navigate('/')
                            })
                    })
                    .catch(error => {
                        logout()
                    })
            } else {
                setUser(null)
            }
        }
    }, [])

    return user ? <Component /> : <Navigate to="/login" />
}

export default PrivateRoute

